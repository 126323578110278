














import AppleLoginService from '~/services/login/AppleLoginService'
import { LoginResult } from '~/models/login-register/types'
import { computed, defineComponent, onMounted } from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { ciApple } from '~/icons/source/brand/apple'
import { useCookies } from '~/compositions/useCookies'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(_props, { emit }) {
    const appleLoginService = useDep(AppleLoginService)
    const { t } = useI18n()
    const cookies = useCookies()

    onMounted(() => {
      appleLoginService.getAppleID()
    })
    const title = computed(() => `${t('continue with')} Apple`)

    function afterLogin(response: LoginResult | null) {
      cookies.clearSessionCookies()
      emit('login-success', response?.redirectUrl)
      if (response === null) {
        emit('set-loading', false)
      }
    }

    function login() {
      emit('click')
      emit('set-loading', true)
      appleLoginService.login(afterLogin)
    }

    return {
      login,
      title,
      ciApple
    }
  }
})
